export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İspanyolca"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkçe"])},
  "lad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladino"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastgele cümle"])},
  "Contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katkıda bulun"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
  "Type something to translate...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevirilecek cümleyi girin..."])},
  "Thank you for your contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katkınız için teşekkürler!"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu otomatik çeviri uygulaması, Avrupa Birliği’nin maddi desteği ile hazırlanmıştır. İçerik tamamıyla Col·lectivaT ve SKAD’ın sorumluluğu altındadır ve Avrupa Birliğinin görüşlerini yansıtmak zorunda değildir."])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu proje Avrupa Birliği tarafından finanse edilmektedir."])}
}