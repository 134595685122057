export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turco"])},
  "lad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladino"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase aleatoria"])},
  "Contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuir"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "Type something to translate...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe algo para traducir..."])},
  "Thank you for your contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por tu contribución!"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación de traducción automática fue preparado con el financiamiento de la Unión Europea. Su contenido es responsabilidad exclusiva de la cooperativa Col·lectivaT y SKAD, y no refleja necesariamente la opinión de la Unión Europea."])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Este proyecto está financiado por la Unión Europea. "])}
}