export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
  "lad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladino"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random Sentence"])},
  "Contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "Type something to translate...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type something to translate..."])},
  "Thank you for your contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your contribution!"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This machine translation application was produced with the financial support of the European Union. Its contents are the sole responsibility of Col·lectivaT and SKAD, and do not necessarily reflect the views of the European Union."])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is funded by the European Union."])}
}