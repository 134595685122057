export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingles"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanyol"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turko"])},
  "lad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladino"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase aleatorya"])},
  "Contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontribuir"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anular"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embiar"])},
  "Type something to translate...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskrive algo para traduizir..."])},
  "Thank you for your contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grasias por tu kontribusyon!"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplikasion de traduksion automatika fue aparejado kon el finansiamiento de la Union Europea. Su kontenido es responsabilitad eksklusiva de la kooperativa Col·lectivaT i SKAD, i no refleja nesesariamente la opinion de la Union Europea."])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este proyekto esta finansiado por la Union Europea."])}
}